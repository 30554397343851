.container {
    margin-top: 24px;
    margin-bottom: 50px;
}

.container > h1 {
    text-align: center;
    margin-bottom: 40px;
    font-weight: normal;
    font-size: 35px;
    color: #3d3d3d;
}
/*---------------------------------------------------------------*/
.report-problem {
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius:12px 0 12px 0;
	background: #292929;
	border:none;
	color:#fff;
    font-size: 10px;
    font-weight: bold;
    padding:6px 5px 6px 5px;
    margin-left: 3px;
    margin-bottom: 4px;
    text-decoration: none;
}
.report-problem:hover {
    font-size: 11px;
    padding:7px 6px 7px 6px;
    transition: 0.25s;
}
.report-problem:active {
    box-shadow: 1px 1px 10px rgb(255, 0, 0) inset, 0 1px 0 rgba(255, 0, 0, 0); 
    outline-style:none;
}
/*---------------------------------------------------------------*/
.clear-text-cache {
    color: #fff;
    text-transform: uppercase;
    background: #413232;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    cursor: pointer;
    text-decoration: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.clear-text-cache:hover {
    background: #434343;
    box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s;
}

@media only screen and (max-width: 768px) {
    .clear-text-cache {
        transform: scale(0.7); 
        bottom: 5px;
        right: 0;
    }
}